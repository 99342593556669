<template>
  <tbody :class="classes">
    <slot></slot>
  </tbody>
</template>

<script>
export default {
  name: "LTBody",
  props: {
    classes: {
      default: "bg-white divide-y divide-gray-200",
    },
  },
};
</script>

<style scoped></style>
