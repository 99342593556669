<template>
  <th scope="col" :class="newClasses">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "Lth",
  props: {
    classes: {
      default: "",
    },
  },
  computed: {
    newClasses() {
      return `px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${this.classes}`;
    },
  },
};
</script>

<style scoped></style>
