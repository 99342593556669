<template>
  <tr @click="$emit('onRowClicked')" :class="classes">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "Ltr",
  props: {
    classes: String,
  },
};
</script>

<style scoped></style>
