<template>
  <div class="w-full">
    <Listbox
      v-on:update:modelValue="setSelectionChanged"
      v-model="selectedCustomer"
    >
      <div class="relative mt-1 text-left">
        <ListboxLabel>Search Customer</ListboxLabel>
        <ListboxButton
          class="
            relative
            w-full
            py-3
            pl-3
            pr-10
            text-left
            rounded-lg
            cursor-default
            focus:outline-none
            focus-visible:ring-2
            focus-visible:ring-opacity-75
            focus-visible:ring-white
            focus-visible:ring-offset-orange-300
            focus-visible:ring-offset-2
            focus-visible:border-indigo-500
            sm:text-sm
            w-full
            bg-gray-100
            border-transparent
          "
        >
          <span class="block truncate">{{
            Object.keys(selectedCustomer).length > 0
              ? selectedCustomer.full_name
              : "Search Customer"
          }}</span>
          <span
            class="
              absolute
              inset-y-0
              right-0
              flex
              items-center
              pr-2
              pointer-events-none
            "
          >
            <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="
              absolute
              w-full
              py-1
              mt-1
              overflow-auto
              text-base
              bg-white
              rounded-md
              shadow-lg
              max-h-60
              ring-1 ring-black ring-opacity-5
              focus:outline-none
              sm:text-sm
            "
          >
            <div class="px-4 py-2">
              <input
                autofocus
                type="text"
                v-model="searchText"
                @input="searchDebounce"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                placeholder="Search Customers"
                name="search-customers"
              />
            </div>
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="(customer, index) in customersSearch"
              :key="index"
              :value="customer"
              as="template"
            >
              <li
                class="cursor-pointer text-left"
                :class="[
                  active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                  'select-none relative py-3 px-4 hover:bg-gray-200',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                  >{{ customer.full_name }}</span
                >
                <span
                  v-if="selected"
                  class="
                    absolute
                    inset-y-0
                    right-0
                    flex
                    items-center
                    pr-4
                    text-amber-600
                  "
                >
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

export default {
  name: "SearchCustomers",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },
  setup() {
    const selectedCustomer = ref({});
    const searchText = ref("");

    return {
      selectedCustomer,
      searchText,
    };
  },
  computed: {
    ...mapGetters(["customersSearch"]),
  },
  methods: {
    setSelectionChanged(value) {
      this.$emit("customer", value);
    },
    searchDebounce() {
      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        this.searchCustomers();
      }, 500);
    },
    searchCustomers() {
      this.$store.dispatch("searchCustomersList", {
        search_txt: this.searchText,
      });
    },
  },
  mounted() {
    if (this.customersSearch.length == 0) {
      this.searchCustomers();
    }
  },
};
</script>

<style scoped></style>
