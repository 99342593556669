<template>
  <div
    :class="getButtonClass"
    class="rounded-md cursor-pointer flex flex-row items-center justify-center"
  >
    <div class="flex flex-row">
      <slot name="icon"></slot>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LButton",
  props: {
    text: {
      default: "",
      type: String,
    },
    type: {
      default: "",
      type: String,
    },
    classes: {
      default: "",
      type: String || Array,
    },
  },
  computed: {
    getButtonClass() {
      if (this.type === "outline") {
        return "brand-button-outline";
      }
      if (this.type == "danger") {
        return "danger-button";
      }
      if (this.type == "success") {
        return "success-button";
      }
      return "brand-button";
    },
  },
};
</script>

<style scoped></style>
