<template>
  <div class="custom-pagination">
    <div class="vs-pagination-content circle vs-component--primary">
      <!--      <vs-button-->
      <!--        icon-->
      <!--        gradient-->
      <!--        color="warn"-->
      <!--        v-if="value && value.length === 1"-->
      <!--        @click="$emit('input', '')"-->
      <!--      >-->
      <!--        <i class="bx bx-x"></i>-->
      <!--      </vs-button>-->
      <div class="vs-pagination">
        <button
          :key="item"
          v-for="item in max"
          :class="item === value ? 'active' : ''"
          @click="$emit('input', item)"
          class="vs-pagination__button"
        >
          {{ item }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LPagination",
  data() {
    return {
      alphabets: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    };
  },
  props: {
    value: {
      default: "",
      type: [String, Number],
    },
    max: {
      default: 3,
      type: [Number],
    },
  },
};
</script>

<style lang="scss">
.custom-pagination {
  .vs-pagination__button.active {
    color: white;
    background-color: rgba(var(--brand), 1);
    box-shadow: 0px 5px 20px 0px rgba(17, 18, 20, 0.3);
  }
}
</style>
