<template>
  <td :class="classes">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "Ltd",
  props: {
    classes: {
      default: "px-6 py-4 whitespace-nowrap text-left",
    },
  },
};
</script>

<style scoped></style>
