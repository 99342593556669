<template>
  <thead :class="classes">
    <slot></slot>
  </thead>
</template>

<script>
export default {
  name: "LTHead",
  props: {
    classes: {
      default: "bg-gray-50",
    },
  },
};
</script>

<style scoped></style>
