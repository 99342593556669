<template>
  <table class="min-w-full divide-y divide-gray-200">
    <slot name="thead"></slot>
    <slot name="tbody"></slot>
  </table>
</template>

<script>
export default {
  name: "LTable",
};
</script>

<style scoped></style>
